$ = jQuery;

/*
* Evento de clique no sanduíche do header
*/
$('.jc-icon-navigation-menu').on('click', function() {
  dataLayer.push({
    'event': 'ga_event',
    'eventCategory': 'Header',
    'eventAction': 'Clique - Buscar',
    'eventLabel': 'Sanduíche'
  });

  gtag('event', 'Header', {
    'eventAction': 'Clique - Buscar',
    'eventLabel': 'Sanduíche'
  });
});

/*
* Evento de clique em buscar do header
*/
$('.jc-icon-search').on('click', function() {
  dataLayer.push({
    'event': 'ga_event',
    'eventCategory': 'Header',
    'eventAction': 'Clique - Buscar',
    'eventLabel': 'Menu'
  });

  gtag('event', 'Header', {
    'eventAction': 'Clique - Buscar',
    'eventLabel': 'Menu'
  });
});

/*
* Evento de clique no menu de categorias do buscar do header
*/
$('#menu-primary_navigation .menu-item a').on('click', function() {

  dataLayer.push({
    'event': 'ga_event',
    'eventCategory': 'Header',
    'eventAction': 'Clique - Menu',
    'eventLabel':$(this).text()
  });

  gtag('event', 'Header', {
    'eventAction': 'Clique - Menu',
    'eventLabel':$(this).text()
  });
});